import { useLocation } from 'react-router-dom';

import MMAL from './components/MMAL';
import R2R from './components/R2R';
import Ineos from './components/Ineos';
import Generic from './components/Generic';
import { useLoggedIn, useSessionExpiry, useConfig } from 'utils/hooks';
import { useEffect } from 'react';

function Header() {
  const loc = useLocation();
  const { doLogout } = useLoggedIn();
  const { expireLogin } = useSessionExpiry(doLogout.bind(null, 'header'));
  const { defaults } = useConfig();

  useEffect(() => {
    if (!defaults?.oemid) return;
    expireLogin('header');
    /* eslint-disable-next-line */
  }, [defaults]);

  // console.log('XXXX ::::', defaults);

  // const client = loc.pathname.split('/')[1];
  const client = loc.pathname.split('/')[1];

  // console.log('HEADER ::::', client);
  if (loc.pathname.split('/')[2] === 'pdf-viewer') return null;
  if (!client || client === 'auth') return <R2R />;
  if (client === 'ineos') return <Ineos />;

  return <Generic />;
}

export default Header;
