import { useCallback, useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';

import { useSupabase } from 'utils/supabase';
import { useToast, useLanguages, useConfig } from 'utils/hooks';
import { useModelFamily } from 'features/modelFamily/hooks';
import {
  AssetCategory,
  Model,
  ModelFamily,
  ModelSeries,
  ModelYear,
} from 'types';
import { useModelSeries } from 'features/modelSeries/hooks';
import { useModel } from 'features/model/hooks';
import { useModelYear } from 'features/modelYear/hooks';
import { useAssetCategories } from 'features/assets/hooks';
import { useCrud } from 'utils/genericData';
import { useParams } from 'react-router';
// import { AssetsModel, AssetsModelFamily, AssetsModelSeries, AssetsModelYear } from 'features/assets/hooks';

type State = any;

const initialState: SearchData | undefined = undefined;

const AssetSearchState = atom({
  key: 'AssetAccessSearch',
  default: {
    client: undefined,
  },
});

export type SearchData = {
  asset: any;
  description: any;
  location: any;
  model: Array<{ assetsaccessid: number; modelid: number }>;
  family: Array<{ assetsaccessid: number; modelfamilyid: number }>;
  series: Array<{ assetsaccessid: number; modelseriesid: number }>;
  year: Array<{ assetsaccessid: number; modelyearid: number }>;
  cats: Array<{ assetsaccessid: number; assetcategoryid: number }>;
  oems: Array<{ assetsaccessid: number; oemid: number }>;
};

export type Result = {
  assetsaccessid: number;
  files: string[];
  description: string;
  date: string;
  year: number;
  modelFamilyDescription: string;
  modelSeriesDescription: string;
  modelDescription: string;
  modelCode: string;
  category: string;
};

export function useSearch(catId?: any) {
  const [state, setState] = useRecoilState(AssetSearchState);
  const supabase = useSupabase();
  const [allIds, setAllIds] = useState<number[]>();
  const [fullList, setFullList] = useState<Result[]>();
  const [status, setStatus] = useState<'loading' | 'done'>('loading');
  const { languageId, messages } = useLanguages();
  const [data, setData] = useState<SearchData>();
  const { data: modelFamilies }: { data: ModelFamily[] | undefined } =
    useModelFamily();
  const { data: modelSeries }: { data: ModelSeries[] | undefined } =
    useModelSeries();
  const { data: models }: { data: Model[] | undefined } = useModel();
  const { data: modelYears }: { data: ModelYear[] | undefined } =
    useModelYear();
  const { data: assetCats }: { data: AssetCategory[] | undefined } =
    useAssetCategories();

  const { data: assets } = useAssets();
  const { data: cats } = useAssetsCats();
  const { data: descr } = useAssetsDescription();
  const { data: mf } = useAssetsModelFamily();
  const { data: ms } = useAssetsModelSeries();
  const { data: m } = useAssetsModel();
  const { data: my } = useAssetsModelYear();
  // const { data: an } = useAssetsLocation();
  const { data: oem } = useAssetsOEM();
  // const { data: div } = useAssetsDivision();
  // const { data: bra } = useAssetsBrand();
  // const { data: rgs } = useAssetsRepairGroup();
  // const { data: rsgs } = useAssetsRepairSubGroup();
  // const { data: rcs } = useAssetsRepairComponent();
  // const { data: aps } = useAssetsAASRAProfile();

  const [currentCat, setCurrentCat] = useState<string>();
  const { defaults } = useConfig();
  const { toast } = useToast();
  const { client }: any = useParams();

  useEffect(() => {
    if (client === state.client && data) return;
    if (
      !defaults ||
      !client ||
      !assets ||
      !descr ||
      !m ||
      !mf ||
      !ms ||
      !my ||
      !cats ||
      !oem
    )
      return;
    const f = async () => {
      console.log('ASSETS ACCESS SEARCH DATA FETCH ******');
      try {
        const d = {
          asset: assets,
          description: descr,
          location: null,
          model: m,
          family: mf,
          series: ms,
          year: my,
          cats: cats,
          oems: oem,
        };
        setData(d);
        setStatus('done');
        setState({ client });
      } catch (error: any) {
        toast('error', 'error', { message: error.message });
      }
    };
    f();
    /* eslint-disable-next-line */
  }, [data, client, assets, descr, m, mf, ms, my, cats, oem, defaults]);

  const buildResults = useCallback(
    (ids: number[]): Result[] => {
      const results: Result[] = [];
      console.log('BUILD ASSET ACCESS RESULTS ::::', data);
      ids?.forEach((id) => {
        const catId: number | undefined = data?.cats?.find(
          ({ assetsaccessid }) => assetsaccessid === id
        )?.assetcategoryid;
        const cat: AssetCategory | undefined = assetCats?.find(
          ({ assetcategoryid }) => assetcategoryid === catId
        );
        const mfs: AssetsModelFamily[] =
          data?.family.filter(({ assetsaccessid }) => assetsaccessid === id) ||
          [];
        const modelFamilyDescription: ModelFamily[] =
          modelFamilies?.filter(({ modelfamilyid: xid }) =>
            mfs.find(({ modelfamilyid: x }) => x === xid)
          ) || [];
        const mss: AssetsModelSeries[] =
          data?.series.filter(({ assetsaccessid }) => assetsaccessid === id) ||
          [];
        const modelSeriesDescription = modelSeries?.filter(
          ({ modelseriesid: xid }) =>
            mss.find(({ modelseriesid: x }) => x === xid)
        );
        const ms: AssetsModel[] =
          data?.model?.filter(({ assetsaccessid }) => assetsaccessid === id) ||
          [];
        const modelCode: Model[] =
          models?.filter(({ modelid: xid }) =>
            ms.find(({ modelid: x }) => x === xid)
          ) || [];
        const asset = data?.asset.find(
          ({ assetsaccessid }) => assetsaccessid === id
        );
        // const loc = data?.location.find(({ assetsaccessid }) => assetsaccessid === id);
        const desc = data?.description.find(
          ({ assetsaccessid }) => assetsaccessid === id
        );
        const mys: AssetsModelYear[] =
          data?.year.filter(({ assetsaccessid }) => assetsaccessid === id) ||
          [];
        const modelYear: ModelYear[] =
          modelYears?.filter(({ modelyearid: xid }) =>
            mys.find(({ modelyearid: x }) => x === xid)
          ) || [];
        // const file = loc ? `${loc?.assettrunk || ''}${loc?.assetbranch || ''}${loc?.assetleaf || ''}${loc?.assetname}` : messages.none;
        results.push({
          assetsaccessid: id,
          description: desc?.assetaccessdescription || messages.none,
          category: cat?.assetcategorydescription,
          year:
            modelYear
              ?.map(
                ({ modelyeardescriptionshort }) => modelyeardescriptionshort
              )
              .join(', ') || messages.none,
          date: asset?.created_at || messages.none,
          // files: [file],
          modelFamilyDescription:
            modelFamilyDescription
              ?.map(({ modelfamilydescription }) => modelfamilydescription)
              ?.join(', ') || messages.none,
          modelSeriesDescription:
            modelSeriesDescription
              ?.map(
                ({ modelseriesdescriptionshort }) => modelseriesdescriptionshort
              )
              ?.join(', ') || messages.none,
          modelCode:
            modelCode
              ?.map(({ modeldescriptionshort }) => modeldescriptionshort)
              .join(', ') || messages.none,
        } as Result);
      });
      return results;
    },
    [data, messages, modelFamilies, modelSeries, models, modelYears, assetCats]
  );

  useEffect(() => {
    if (
      catId === currentCat ||
      !modelFamilies ||
      !modelSeries ||
      !models ||
      !data ||
      !assetCats
    )
      return;
    let allids: number[] = [];
    if (catId)
      allids = data?.cats
        ?.filter(
          ({ assetcategoryid }) =>
            assetcategoryid.toString() === catId.toString()
        )
        .map(({ assetsaccessid }) => assetsaccessid);
    else allids = data?.asset?.map(({ assetsaccessid }) => assetsaccessid);
    allids =
      data?.oems
        ?.filter(
          ({ oemid, assetsaccessid }) =>
            allids.find((x) => x === assetsaccessid) &&
            oemid?.toString() === defaults?.oemid.toString()
        )
        ?.map(({ assetsaccessid }) => assetsaccessid) || [];
    setAllIds(allids);
    console.log('with cat', allids, defaults);
    setFullList(buildResults(allids));
    setCurrentCat(catId);
  }, [
    buildResults,
    data,
    catId,
    currentCat,
    modelFamilies,
    modelSeries,
    models,
    defaults,
    assetCats,
  ]);

  useEffect(() => {
    if (
      !modelFamilies ||
      !modelSeries ||
      !models ||
      !data ||
      !assetCats ||
      fullList
    )
      return;
    if (catId === undefined) {
      console.log('without cat');
      let allids = data?.asset?.map(({ assetsaccessid }) => assetsaccessid);
      console.log('allids :::::', defaults, allids, data.oems);
      allids =
        data?.oems
          ?.filter(
            ({ oemid, assetsaccessid }) =>
              allids.find(
                (x) => x?.toString() === assetsaccessid?.toString()
              ) && oemid?.toString() === defaults?.oemid.toString()
          )
          ?.map(({ assetsaccessid }) => assetsaccessid) || [];
      setAllIds(allids);
      console.log('allids 2 :::::', allids);
      setFullList(buildResults(allids));
    }
    /* eslint-disable-next-line */
  }, [
    buildResults,
    data,
    catId,
    currentCat,
    modelFamilies,
    modelSeries,
    models,
    defaults,
    assetCats,
  ]);

  const search = (q: State): Result[] => {
    let list: number[] = allIds || [];
    console.log(' list 1 ::::', list);
    if (q.search) {
      const x = data?.description
        .filter(
          ({
            assetsaccessid,
            assetaccessdescription,
            assetaccessdescriptionshort,
          }) =>
            list.find((id) => id === assetsaccessid) &&
            (assetaccessdescription
              .toLowerCase()
              .includes(q.search.toLowerCase()) ||
              assetaccessdescriptionshort
                .toLowerCase()
                .includes(q.search.toLowerCase()))
        )
        .map(({ assetsaccessid }) => assetsaccessid);
      list = x;
    }
    if (q.modelfamilyid >= 0) {
      const x = data?.family
        .filter(
          ({ assetsaccessid, modelfamilyid }) =>
            list.find((id) => id === assetsaccessid) &&
            modelfamilyid === q.modelfamilyid
        )
        .map(({ assetsaccessid }) => assetsaccessid);
      if (x) list = x;
    }
    if (q.modelseriesid >= 0) {
      const x = data?.series
        .filter(
          ({ assetsaccessid, modelseriesid }) =>
            list.find((id) => id === assetsaccessid) &&
            modelseriesid === q.modelseriesid
        )
        .map(({ assetsaccessid }) => assetsaccessid);
      if (x) list = x;
    }
    if (q.modelid >= 0) {
      const x = data?.model
        .filter(
          ({ assetsaccessid, modelid }) =>
            list.find((id) => id === assetsaccessid) && modelid === q.modelid
        )
        .map(({ assetsaccessid }) => assetsaccessid);
      if (x) list = x;
    }
    if (q.modelyearid >= 0) {
      const x = data?.year
        .filter(
          ({ assetsaccessid, modelyearid }) =>
            list.find((id) => id === assetsaccessid) &&
            modelyearid === q.modelyearid
        )
        .map(({ assetsaccessid }) => assetsaccessid);
      if (x) list = x;
    }
    console.log('result list ::::', q, list);
    return buildResults(list);
  };

  return { search, status, fullList, data, buildResults };
}

// Assets crud
export type Assets = {
  assetsaccessid: number;
};

const iStateAssets: Assets[] | undefined = undefined;

const AssetsState = atom({
  key: 'AssetsAccess',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssets() {
  const xport = useCrud(
    AssetsState,
    'tb_assets_access',
    undefined,
    (left: Assets, right: Assets) =>
      left.assetsaccessid === right.assetsaccessid,
    (d: Assets) => ({ assetsaccessid: d.assetsaccessid })
  );

  return xport;
}

// Assets category crud
export type AssetsCat = {
  assetsaccessid: number;
  assetcategoryid: number;
};

const iStateAssetsCat: AssetsCat[] | undefined = undefined;

const AssetsCatsState = atom({
  key: 'AssetsAccessCats',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsCats() {
  const xport = useCrud(
    AssetsCatsState,
    'tb_assets_access_assetcategory',
    'tb_assets_access',
    (left: AssetsCat, right: AssetsCat) => {
      console.log(left, right);
      return (
        left.assetsaccessid === right.assetsaccessid &&
        left.assetcategoryid === right.assetcategoryid
      );
    },
    (d: AssetsCat) =>
      ({
        assetsaccessid: d.assetsaccessid,
        assetcategoryid: d.assetcategoryid,
      } as AssetsCat)
  );

  return xport;
}

// Assets description crud
export type AssetsDescription = {
  assetsaccessid: number;
  languageid: number;
  assetaccessdescription: string;
  assetaccessdescriptionshort: string;
};
const iStateAssetsDescr: AssetsDescription[] | undefined = undefined;

const AssetsDescriptionState = atom({
  key: 'AssetsAccessDescriptionCats',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsDescription() {
  const xport = useCrud(
    AssetsDescriptionState,
    'tb_assets_access_description',
    'tb_assets_access',
    (left: AssetsDescription, right: AssetsDescription) =>
      left.assetsaccessid === right.assetsaccessid &&
      left.languageid === right.languageid,
    (d: AssetsDescription) =>
      ({
        assetsaccessid: d.assetsaccessid,
        languageid: d.languageid,
      } as AssetsDescription)
  );

  return xport;
}

// Assets model family crud
export type AssetsModelFamily = {
  assetsaccessid: number;
  modelfamilyid: number;
};
const iStateAssetsModelFamily: AssetsModelFamily[] | undefined = undefined;

const AssetsModelFamilyState = atom({
  key: 'AssetsAccessModelFamilyCats',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsModelFamily() {
  const xport = useCrud(
    AssetsModelFamilyState,
    'tb_assets_access_modelfamily',
    'tb_assets_access',
    (left: AssetsModelFamily, right: AssetsModelFamily) =>
      left.assetsaccessid === right.assetsaccessid &&
      left.modelfamilyid === right.modelfamilyid,
    (d: AssetsModelFamily) => ({
      assetsaccessid: d.assetsaccessid,
      modelfamilyid: d.modelfamilyid,
    })
  );

  return xport;
}

// Assets model family crud
export type AssetsModelSeries = {
  assetsaccessid: number;
  modelseriesid: number;
};
const iStateAssetsModelSeries: AssetsModelSeries[] | undefined = undefined;

const AssetsModelSeriesState = atom({
  key: 'AssetsAccessModelSeriesCats',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsModelSeries() {
  const xport = useCrud(
    AssetsModelSeriesState,
    'tb_assets_access_modelseries',
    'tb_assets_access',
    (left: AssetsModelSeries, right: AssetsModelSeries) =>
      left.assetsaccessid === right.assetsaccessid &&
      left.modelseriesid === right.modelseriesid,
    (d: AssetsModelSeries) => ({
      assetsaccessid: d.assetsaccessid,
      modelseriesid: d.modelseriesid,
    })
  );

  return xport;
}

// Assets model crud
export type AssetsModel = {
  assetsaccessid: number;
  modelid: number;
};
const iStateAssetsModel: AssetsModel[] | undefined = undefined;

const AssetsModelState = atom({
  key: 'AssetsAccessModels',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsModel() {
  const xport = useCrud(
    AssetsModelState,
    'tb_assets_access_model',
    'tb_assets_access',
    (left: AssetsModel, right: AssetsModel) =>
      left.assetsaccessid === right.assetsaccessid &&
      left.modelid === right.modelid,
    (d: AssetsModel) => ({
      assetsaccessid: d.assetsaccessid,
      modelid: d.modelid,
    })
  );

  return xport;
}

// Assets model year crud
export type AssetsModelYear = {
  assetsaccessid: number;
  modelyearid: number;
};
const iStateAssetsModelYear: AssetsModelYear[] | undefined = undefined;

const AssetsModelYearState = atom({
  key: 'AssetsAccessModelYears',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsModelYear() {
  const xport = useCrud(
    AssetsModelYearState,
    'tb_assets_access_modelyear',
    'tb_assets_access',
    (left: AssetsModelYear, right: AssetsModelYear) =>
      left.assetsaccessid === right.assetsaccessid &&
      left.modelyearid === right.modelyearid,
    (d: AssetsModelYear) => ({
      assetsaccessid: d.assetsaccessid,
      modelyearid: d.modelyearid,
    })
  );

  return xport;
}

// Assets location crud
export type AssetsLocation = {
  assetsaccessid: number;
  languageid: number;
  assetname: string;
  assetsystem: string;
  created_at?: string;
};

// const iStateAssetsLocation: AssetsLocation[] | undefined = undefined;

// const AssetsLocationState = atom({
//   key: 'AssetsAccessLocations',
//   default: {
//     data: undefined,
// rawData: undefined,
// languageId: 1,
//     status: undefined
//   }
// });

// export function useAssetsLocation() {
//   const xport = useCrud(
//     AssetsLocationState,
//     'tb_assets_access_location',
//     'tb_assets_access',
//     (left: AssetsLocation, right: AssetsLocation) => left.assetsaccessid === right.assetsaccessid && left.languageid === right.languageid,
//     (d: AssetsLocation) => ({ assetsaccessid: d.assetsaccessid, languageid: d.languageid }),
//   );

//   return xport;
// };

// Assets oem crud
export type AssetsOEM = {
  assetsaccessid: number;
  oemid: number;
};
const iStateAssetsOEM: AssetsOEM[] | undefined = undefined;

const AssetsOEMState = atom({
  key: 'AssetsAccessOEMs',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsOEM() {
  const xport = useCrud(
    AssetsOEMState,
    'tb_assets_access_oem',
    'tb_assets_access',
    (left: AssetsOEM, right: AssetsOEM) =>
      left.assetsaccessid === right.assetsaccessid &&
      left.oemid === right.oemid,
    (d: AssetsOEM) => ({ assetsaccessid: d.assetsaccessid, oemid: d.oemid })
  );

  return xport;
}

// Assets division crud
export type AssetsDivision = {
  assetsaccessid: number;
  divisionid: number;
};
const iStateAssetsDivision: AssetsDivision[] | undefined = undefined;

const AssetsDivisionState = atom({
  key: 'AssetsAccessDivisions',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsDivision() {
  const xport = useCrud(
    AssetsDivisionState,
    'tb_assets_access_division',
    'tb_assets_access',
    (left: AssetsDivision, right: AssetsDivision) =>
      left.assetsaccessid === right.assetsaccessid &&
      left.divisionid === right.divisionid,
    (d: AssetsDivision) => ({
      assetsaccessid: d.assetsaccessid,
      divisionid: d.divisionid,
    })
  );

  return xport;
}

// Assets brand crud
export type AssetsBrand = {
  assetsaccessid: number;
  brandid: number;
};
const iStateAssetsBrand: AssetsBrand[] | undefined = undefined;

const AssetsBrandState = atom({
  key: 'AssetsAccessBrands',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsBrand() {
  const xport = useCrud(
    AssetsBrandState,
    'tb_assets_access_brand',
    'tb_assets_access',
    (left: AssetsBrand, right: AssetsBrand) =>
      left.assetsaccessid === right.assetsaccessid &&
      left.brandid === right.brandid,
    (d: AssetsBrand) => ({
      assetsaccessid: d.assetsaccessid,
      brandid: d.brandid,
    })
  );

  return xport;
}

// Assets repair group crud
export type AssetsRepairGroup = {
  assetsaccessid: number;
  repairgroupid: string;
};
const iStateAssetsRepairGroup: AssetsRepairGroup[] | undefined = undefined;

const AssetsRepairGroupState = atom({
  key: 'AssetsAccessRepairGroups',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsRepairGroup() {
  const xport = useCrud(
    AssetsRepairGroupState,
    'tb_assets_access_repairgroup',
    'tb_assets_access',
    (left: AssetsRepairGroup, right: AssetsRepairGroup) =>
      left.assetsaccessid === right.assetsaccessid &&
      left.repairgroupid === right.repairgroupid,
    (d: AssetsRepairGroup) => ({
      assetsaccessid: d.assetsaccessid,
      repairgroupid: d.repairgroupid,
    })
  );

  return xport;
}

// Assets repair sub group crud
export type AssetsRepairSubGroup = {
  assetsaccessid: number;
  repairsubgroupid: string;
};
const iStateAssetsRepairSubGroup: AssetsRepairSubGroup[] | undefined =
  undefined;

const AssetsRepairSubGroupState = atom({
  key: 'AssetsAccessRepairSubGroups',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsRepairSubGroup() {
  const xport = useCrud(
    AssetsRepairSubGroupState,
    'tb_assets_access_repairsubgroup',
    'tb_assets_access',
    (left: AssetsRepairSubGroup, right: AssetsRepairSubGroup) =>
      left.assetsaccessid === right.assetsaccessid &&
      left.repairsubgroupid === right.repairsubgroupid,
    (d: AssetsRepairSubGroup) => ({
      assetsaccessid: d.assetsaccessid,
      repairsubgroupid: d.repairsubgroupid,
    })
  );

  return xport;
}

// Assets repair component crud
export type AssetsRepairComponent = {
  assetsaccessid: number;
  repaircomponentid: string;
};
const iStateAssetsRepairComponent: AssetsRepairComponent[] | undefined =
  undefined;

const AssetsRepairComponentState = atom({
  key: 'AssetsAccessRepairComponents',
  default: {
    data: undefined,
    rawData: undefined,
    languageId: 1,
    status: undefined,
  },
});

export function useAssetsRepairComponent() {
  const xport = useCrud(
    AssetsRepairComponentState,
    'tb_assets_access_repaircomponent',
    'tb_assets_access',
    (left: AssetsRepairComponent, right: AssetsRepairComponent) =>
      left.assetsaccessid === right.assetsaccessid &&
      left.repaircomponentid === right.repaircomponentid,
    (d: AssetsRepairComponent) => ({
      assetsaccessid: d.assetsaccessid,
      repaircomponentid: d.repaircomponentid,
    })
  );

  return xport;
}

// Assets repair component crud
// export type AssetsAASRAProfile = {
//   assetsaccessid: number,
//   aasraprofileid: string
// }

// const iStateAssetsAASRAProfile: AssetsAASRAProfile[] | undefined = undefined;

// const AssetsAASRAProfileState = atom({
//   key: 'AssetsAccessAASRAProfiles',
//   default: {
//     data: undefined,
// rawData: undefined,
// languageId: 1,
//     status: undefined
//   }
// });

// export function useAssetsAASRAProfile() {
//   const xport = useCrud(
//     AssetsAASRAProfileState,
//     'tb_assets_access_aasraprofile',
//     'tb_assets_access',
//     (left: AssetsAASRAProfile, right: AssetsAASRAProfile) => left.assetsaccessid === right.assetsaccessid && left.aasraprofileid === right.aasraprofileid,
//     (d: AssetsAASRAProfile) => ({ assetsaccessid: d.assetsaccessid, aasraprofileid: d.aasraprofileid }),
//     undefined,
//     undefined,
//     undefined,
//     true
//   );

//   return xport;
// };
