import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'components/Theme/Context';
import styled from 'styled-components';

type Props = {
  autoFocus?: boolean;
  containerStyle?: any;
  disabled?: boolean;
  example?: string;
  id1?: string;
  height?: string;
  label?: string;
  name?: string;
  onChange?: Function;
  password?: boolean;
  style?: object;
  value?: any;
  required?: boolean;
  onBlur?: any;
  children?: any;
  type?: any;
  labelStyle?: any;
};

const TextInputLabel = styled.div`
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: .6rem;
  color:#000;
  line-height: 1.5;
  letter-spacing: .7px; 
`;
// Style fort TextInput
// style={{
//   height: '42px',
//   maxWidth: 450,
//   color: '#f5f5f6',
//   letterSpacing: '0.1em',
//   backgroundColor: '#0c111d',
//   border: '1px solid #333741',
//   borderRadius: '.5rem',
//   minHeight: '2.75rem',
//   marginBottom: '0',
//   padding: '.5rem .75rem',
//   fontFamily: 'Neuehaasdisplay mediu, sans-serif',
//   fontSize: '1rem',
//   fontWeight: 400,
//   lineHeight: '1.6',
//   transition: 'all .2s',
//   '::placeholder': {
//     fontWeight: 200,
//     color: 'black',
//   },
// }
const TextInput = styled.input`
  font-family: NeueHaasGroteskDisplay, sans-serif;
  font-weight: 400;
  border: 1px solid #ebe7e0;
  border-radius: 8px;
  height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  transition: transform 0.2s, border-color 0.2s;
  transform: translate(0);
  color: #2b2b2b;
  margin-bottom: 0;
  box-shadow: 0 1px 2px rgba(178, 170, 158, 0.32), 0 0 0 1px #efede8;

  ::placeholder {
    font-weight: 400;
    letter-spacing: 0em;
    /* color: grey; */
    /* font-family: Neuehaasdisplay mediu, sans-serif; */
  }

  :focus {
    transform: translate(0, -2px);
    border-color: #303db4;
    margin-bottom: 0;
    box-shadow: 0 1px 2px rgba(178, 170, 158, 0.32), 0 0 0 1px #efede8;
    /* border-color: #b692f6 !important; */
    /* box-shadow: 0 0 0 4px rgba(158, 119, 237, 0.24) !important; */
    outline: none;
  }
  :hover {
    /* border-color: #303db4; */
    /* box-shadow: 0 0 0 1px gray; */
  }
  :disabled {
    background-color: #333741;
    color: #f5f5f6;
  }
`;

function TextInput2({
  autoFocus,
  containerStyle,
  disabled,
  example,
  id1,
  height,
  label,
  name,
  onChange,
  password,
  style,
  value,
  required,
  onBlur,
  children,
  type,
  labelStyle,
}: Props) {
  const [id, setId] = useState<string>();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (!id1) {
      import('uuid').then((uuid) => {
        setId(uuid.v1());
      });
    } else {
      setId(id1);
    }
  }, [id1]);

  return (
    // <div style={{ width: '15rem', marginLeft: '2em', ...containerStyle }}>
    <div style={{ ...containerStyle }}>
      <TextInputLabel className="ml-2 mt-2 text-gray-400 uppercase">
        <span style={{ ...labelStyle }}>
          {label} {required ? '*' : ''}
        </span>
      </TextInputLabel>
      {disabled && (
        <TextInput
          className="relative bg-gray-100 appearance-none outline-none border-b-2 border-t-0 border-l-0 border-r-0 border-select-1 pb-2 pl-2 w-full bg-down-arrow bg-no-repeat bg-bottom-4"
          id={id}
          placeholder={example || label}
          autoComplete={password ? 'password' : label?.split(' ').join('-')}
          type={password ? 'password' : type || 'text'}
          value={value}
          style={style}
          disabled={disabled}
          name={name}
          onChange={(e: any) => onChange?.(e.target.value)}
          title={label}
          autoFocus={autoFocus}
          height={height}
          onBlur={onBlur}
        >
          {children}
        </TextInput>
      )}

      {!disabled && (
        <TextInput
          className="w-full"
          id={id}
          name={name}
          placeholder={example || label}
          value={value}
          type={password ? 'password' : 'text'}
          style={style}
          onChange={(e: any) => onChange?.(e.target.value)}
          title={label}
          autoFocus={autoFocus}
          height={height}
          onBlur={onBlur}
        >
          {children}
        </TextInput>
      )}
    </div>
  );
}

export default TextInput2;
