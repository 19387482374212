import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'components/Theme/Context';
import styled from 'styled-components';

type Props = {
  autoFocus?: boolean;
  containerStyle?: any;
  disabled?: boolean;
  example?: string;
  id1?: string;
  height?: string;
  label?: string;
  name?: string;
  onChange?: Function;
  password?: boolean;
  style?: object;
  value?: any;
  required?: boolean;
  onBlur?: any;
  children?: any;
  type?: any;
  labelStyle?: any;
};

const TextInputLabel = styled.div`
  font-size: 14px;
`;

function TextInput2({
  autoFocus,
  containerStyle,
  disabled,
  example,
  id1,
  height,
  label,
  name,
  onChange,
  password,
  style,
  value,
  required,
  onBlur,
  children,
  type,
  labelStyle,
}: Props) {
  const [id, setId] = useState<string>();
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (!id1) {
      import('uuid').then((uuid) => {
        setId(uuid.v1());
      });
    } else {
      setId(id1);
    }
  }, [id1]);

  return (
    // <div style={{ width: '15rem', marginLeft: '2em', ...containerStyle }}>
    <div style={{ ...containerStyle }}>
      <TextInputLabel className="ml-2 mt-2 text-gray-400 uppercase">
        <span style={{ ...labelStyle }}>
          {label} {required ? '*' : ''}
        </span>
      </TextInputLabel>
      {disabled && (
        <input
          className="relative bg-gray-100 appearance-none outline-none border-b-2 border-t-0 border-l-0 border-r-0 border-select-1 pb-2 pl-2 w-full bg-down-arrow bg-no-repeat bg-bottom-4"
          id={id}
          placeholder={example || label}
          autoComplete={password ? 'password' : label?.split(' ').join('-')}
          type={password ? 'password' : type || 'text'}
          value={value}
          style={style}
          disabled={disabled}
          name={name}
          onChange={(e: any) => onChange?.(e.target.value)}
          title={label}
          autoFocus={autoFocus}
          height={height}
          onBlur={onBlur}
        >
          {children}
        </input>
      )}

      {!disabled && (
        <input
          className="relative pt-2 bg-gray-100 appearance-none outline-none border-b-2 border-t-0 border-l-0 border-r-0 border-select-1 pb-2 pl-2 w-full bg-down-arrow bg-no-repeat bg-bottom-4"
          id={id}
          name={name}
          placeholder={example || label}
          value={value}
          type={password ? 'password' : 'text'}
          style={style}
          onChange={(e: any) => onChange?.(e.target.value)}
          title={label}
          autoFocus={autoFocus}
          height={height}
          onBlur={onBlur}
        >
          {children}
        </input>
      )}
    </div>
  );
}

export default TextInput2;
