import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { LanguageContext } from 'components/Language/Context';
import { useLoggedIn, useConfig } from 'utils/hooks';
import { useOEMUserList } from 'features/page/hooks';
import { useAASRAProfiles } from 'features/aasraProfile/hooks';
import { useUserAasra, useUserAccess } from 'features/oemAdmin/hooks';

function Generic({
  noHamburger,
  showAssetMenu,
  showAssetMenuState,
  showMenuFn,
}: {
  noHamburger?: boolean;
  showAssetMenu?: any;
  showAssetMenuState?: any;
  showMenuFn?: Function;
}) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { user: authUser } = useLoggedIn(true, true);
  const { messages } = useContext(LanguageContext);
  const { defaults } = useConfig();
  const {
    hasAdmin,
    hasAssets,
    hasAccessProfiles,
    hasUrlAuth,
    hasOemAdmin,
    hasGapAdmin,
    hasUsersData,
    hasActiveSubs,
    hasSupport,
    hasClientAdmin,
  } = useOEMUserList();
  const history = useHistory();

  const { doLogout, user } = useLoggedIn(true, true);
  const { data: userProfiles } = useAASRAProfiles();
  const { data: uaccess, fetchData } = useUserAccess(user?.email);
  const { data: userAssra, fetchData: fetchDataAasra } = useUserAasra(
    user?.email
  );

  const loc = window.location.pathname.split('/')[2];
  const sub = window.location.pathname.split('/')[3];
  const client = window.location.pathname.split('/')[1];

  useEffect(() => {
    if (!defaults) return;
    const fav: any = document.getElementById('favicon');
    // console.log('FAVICON', defaults);
    if (defaults?.faviconurl && client) fav.href = defaults?.faviconurl;
    // else fav.href = '/public/favicon.ico';
  }, [defaults, client]);

  // keep the Generic (top menu) synchrozined with the AssetMenu state.
  useEffect(() => {
    setShowMenu(showAssetMenuState);
  }, [showAssetMenuState]);

  if (!defaults) return null;

  const toggleMenu = () => setShowMenu(!showMenu);

  const navigateAndToggleMenu = async (path?: string) => {
    await history.push(`/${client}/${path}`);
    showMenuFn?.();
    if (!showMenu) {
      toggleMenu();
      showAssetMenu();
    }
  };

  // console.log('MAIN MENU ::::', hasGapAdmin);
  const userName = !!authUser?.firstname
    ? `${authUser?.firstname} ${authUser?.surname}`
    : authUser?.email?.split('@')[0];

  console.log({ userAssra, userProfiles });
  const roles = userProfiles?.filter((x: any) =>
    ['Global Controller', 'OEM Administrator', 'OEM | Internal User'].includes(
      x?.aasraprofiledescription
    )
  );
  const hasSAPMenu = userAssra?.find((x) =>
    roles?.find((y) => y.aasraprofileid === x.aasraprofileid)
  );

  return (
    <div
      className="flex w-full items-center pr-3"
      style={{
        color: '#4a4340 !important',
        fontFamily: 'NeueHaasGroteskDisplay, sans-serif',
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: '0.02em',
        marginRight: '1rem',
      }}
    >
      <div className="flex">
        <span
          className={`text-center mr-4 ${
            loc === undefined ? 'nav-active' : 'nav-inactive'
          }`}
        >
          <Link onClick={() => navigateAndToggleMenu()} to={`/${client}`}>
            {messages.home}
          </Link>
        </span>

        <span
          className={`text-center mr-4 ${
            loc === 'public' ? 'nav-active' : 'nav-inactive'
          }`}
        >
          <button
            onClick={() => history.push(`/${client}/public`)}
            style={{ outline: 0, fontWeight: 600 }}
          >
            <span>{messages.publicDocs}</span>
          </button>
        </span>

        {hasAssets && client !== 'r2r' && (
          <span
            className={`text-center mr-4 ${
              loc === 'assets' ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link
              onClick={() => navigateAndToggleMenu('assets')}
              to={`/${client}/assets`}
              className="top-menu"
            >
              {messages.findAssets}
            </Link>
          </span>
        )}
        {(hasAdmin && client !== 'r2r') ||
        (hasOemAdmin && client !== 'r2r') ||
        hasGapAdmin ? (
          <span
            className={`text-center mr-4 ${
              loc === 'admin' && sub !== 'access'
                ? 'nav-active'
                : 'nav-inactive'
            }`}
          >
            <Link
              onClick={() => navigateAndToggleMenu('admin')}
              to={`/${client}/admin`}
              className="top-menu"
            >
              {messages.admin}
            </Link>
          </span>
        ) : (
          <span style={{ width: '220px' }}></span>
        )}
        {hasAccessProfiles && client !== 'r2r' && (
          <span
            className={`text-center mr-4 ${
              loc === 'access' ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link
              onClick={() => navigateAndToggleMenu('access')}
              to={`/${client}/access`}
              className="top-menu"
            >
              {messages.accessProfiles}
            </Link>
          </span>
        )}
        {/*{hasUrlAuth && client !== 'r2r' && (
          <span
            className={`text-center text-xxs font-normal font-sans mr-10 ${
              loc === 'urlAuthenticator' ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link
              to={`/${client}/urlAuthenticator`}
              style={{
                color: '#94969c',
                fontFamily: 'Neuehaasdisplay Mediu, sans-serif',
                fontSize: '16px',
                fontWeight: 700,
              }}
            >
              {messages.AssetExplorer}
            </Link>
          </span>
            )}*/}
        {hasClientAdmin && client !== 'r2r' && (
          <span
            className={`text-center mr-4 ${
              loc === 'administrator' ||
              sub === 'activesubs' ||
              sub === 'clientoemadmin' ||
              sub === 'dashboard'
                ? 'nav-active'
                : 'nav-inactive'
            }`}
          >
            <Link
              onClick={() => navigateAndToggleMenu('administrator')}
              to={`/${client}/administrator`}
              className="top-menu"
            >
              {messages.clientAdmin}
            </Link>
          </span>
        )}
        {/* {hasUsersData && client !== 'r2r' && (
          <span
            className={`text-center text-xxs font-normal font-sans mr-10 ${
              sub === 'usersdata' ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link to={`/${client}/support/usersdata`} className="top-menu">
              {messages.hasUsersData}
            </Link>
          </span>
        )}
        {hasActiveSubs && client !== 'r2r' && (
          <span
            className={`text-center text-xxs font-normal font-sans mr-10 ${
              sub === 'activesubs' ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link to={`/${client}/support/activesubs`} className="top-menu">
              {messages.activeSubsShort}
            </Link>
          </span>
        )} */}
        {hasSAPMenu && client !== 'r2r' && (
          <span
            className={`text-center mr-4 ${
              loc === 'support' && !sub ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link
              onClick={() => navigateAndToggleMenu('sapReport')}
              to={`/${client}/sapReport`}
              className="top-menu"
            >
              {messages.sapReports}
            </Link>
          </span>
          // <span
          //   className={`text-center mr-10 ${
          //     loc === 'support' && !sub ? 'nav-active' : 'nav-inactive'
          //   }`}
          // >
          //   <Link
          //     onClick={() => navigateAndToggleMenu('support')}
          //     to={`/${client}/support`}
          //     className="top-menu"
          //     style={{
          //       color: '#94969c',
          //       fontFamily: 'Neuehaasdisplay Mediu, sans-serif',
          //       fontSize: '16px',
          //       fontWeight: 700,
          //     }}
          //   >
          //     {messages.support}
          //   </Link>
          // </span>
        )}

        {hasSupport && client !== 'r2r' && (
          <span
            className={`text-center mr-4 ${
              loc === 'support' && !sub ? 'nav-active' : 'nav-inactive'
            }`}
          >
            <Link
              onClick={() => navigateAndToggleMenu('support')}
              to={`/${client}/support`}
              className="top-menu"
            >
              {messages.support}
            </Link>
          </span>
        )}
        <span className="text-center">
          {messages.hello}, {userName}
        </span>
      </div>
      {!noHamburger && (
        // <button className="p-3 m4" onClick={() => toggleMenu()}>
        <span />
        // </button>
      )}
    </div>
  );
}

export default Generic;
