import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useState, useContext, useEffect, useRef } from 'react';

import { LanguageContext } from 'components/Language/Context';
import { useConfig, useLoggedIn } from 'utils/hooks';
import NavMenu from './Menu';
import Menu from '../Menu';

const Container = styled.div`
  width: 100%;
`;

const Top = styled.div`
  width: 100%;
  background-color: #00142c;
  background-image: url(https://ineos-prd-cdn.azureedge.net/-/media/themes/ial/ialshared/ialshared/images/header-bg.jpg);
  background-position: -60px 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  padding: 12px;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 70px;
  margin-left: 12px;
`;

const Bottom = styled.div`
  padding: 12px 0 12px 12px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: white;
  height: 50px;
`;

const TitleLogo = styled.img`
  height: 17px;
  margin-left: 12px;
`;

const MenuWrapperTop = styled.div<any>`
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  font-family: mmcregular;

  a {
    color: white;
  }
  span {
    color: white;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 12px;
    text-decoration: none;
    font-weight: 700;
    display: block;
  }
  span.nav-active {
    border-bottom: 1px solid ${(props) => props.$defaults?.fontcolour};
  }
`;

const MenuWrapper = styled.div<any>`
  white-space: nowrap;
  font-size: 16px;
  font-family: mmcregular;

  span {
    color: black;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 16px;
    text-decoration: none;
    font-weight: 700;
    display: block;
  }
  span.nav-active {
    border-bottom: 2px solid #b61515;
  }
`;

const LoginMenuWrapper = styled.div<any>`
  white-space: nowrap;
  font-size: 16px;
  font-family: mmcregular;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 0.75em;

  a {
    color: black;
  }

  span {
    color: black;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 16px;
    text-decoration: none;
    font-weight: 700;
    display: block;
  }
  span.nav-active {
    border-bottom: 1px solid ${(props) => props.$defaults?.fontcolour};
  }
`;

const HanburgerWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: #b61515;
  span {
    color: white;
  }
`;

export default function IneosHeader() {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { user: authUser } = useLoggedIn(true, true);
  const { messages } = useContext(LanguageContext);
  const { defaults } = useConfig();

  useEffect(() => {
    const handleClose = (e) => {
      if (e?.srcElement?.className === 'top-menu') return;
      // if (ref.current && ref.current.contains(e.target)) return;
      if (showMenu) setShowMenu(false);
    };
    window.document.addEventListener('click', handleClose);
    const ifr: any = window.document.getElementById('iframe-content');
    if (ifr?.contentWindow)
      ifr.contentWindow.addEventListener('click', handleClose);
    return () => {
      window.document.removeEventListener('click', handleClose);
      if (ifr?.contentWindow)
        ifr.contentWindow.removeEventListener('click', handleClose);
    };
  }, [setShowMenu, showMenu]);

  useEffect(() => {
    if (!defaults) return;
    const fav: any = document.getElementById('favicon');
    // console.log('FAVICON', defaults);
    if (defaults?.faviconurl) fav.href = defaults?.faviconurl;
    // else fav.href = '/public/favicon.ico';
  }, [defaults]);

  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <Container id="r2r-header">
      <Top>
        <Logo src="https://ineos-prd-cdn.azureedge.net/au/-/media/project/ial/shared/header/ineos-logo-white.svg?h=18&iar=0&w=69&rev=-1&hash=FADC6803B6A7A4F4472A9396F799DEEA" />
        <MenuWrapperTop className="flex justify-end w-full items-center pr-3">
          <div className="flex" style={{ whiteSpace: 'nowrap' }}>
            <span className="text-center text-xxs font-normal font-sans mr-10">
              <a href="https://ineosgrenadier.com/en/au/#">
                {messages.grenadierKit}
              </a>
            </span>
            <span className="text-center text-xxs font-normal font-sans mr-10">
              <a href="https://media.ineosgrenadier.com/en/?lang=en">
                {messages.press}
              </a>
            </span>
            <span className="text-center text-xxs font-normal font-sans mr-10">
              <a href="https://ineosgrenadier.com/en/au/careers">
                {messages.cariers}
              </a>
            </span>
            <span className="text-center text-xxs font-normal font-sans">
              <a href="https://ineosgrenadier.com/en/au/contact-us">
                {messages.contact}
              </a>
            </span>
          </div>
        </MenuWrapperTop>
      </Top>
      <Bottom>
        <TitleLogo src="https://ineos-prd-cdn.azureedge.net/au/-/media/project/ial/ialshared/header/grenadier-logo.svg?h=34&iar=0&w=434&rev=-1&hash=37F945BEB1DD99250E8077FAC06C3650" />
        <LoginMenuWrapper className="flex justify-end w-full items-center pr-6">
          {!authUser?.email && (
            <div className="flex" style={{ whiteSpace: 'nowrap' }}>
              <span className="text-center text-xxs font-normal font-sans mr-10">
                <Link to="/">{messages.home}</Link>
              </span>
              <span className="text-center text-xxs font-normal font-sans">
                <Link to="/ineos/login">{messages.login}</Link>
              </span>
            </div>
          )}
        </LoginMenuWrapper>
        <MenuWrapper className="flex justify-start w-full items-center pr-3 pl-10">
          {authUser?.email && (
            <NavMenu
              noHamburger
              showAssetMenuState={false}
              showAssetMenu={() => {}}
              showMenuFn={() => setShowMenu(true)}
            />
          )}
        </MenuWrapper>

        {authUser?.email && (
          <HanburgerWrapper>
            <button className="p-4 pt-3" onClick={() => toggleMenu()}>
              <span className="fas fa-bars" />
            </button>
          </HanburgerWrapper>
        )}
        {authUser?.email && (
          <Menu
            setShowMenu={toggleMenu}
            showMenu={showMenu}
            messages={messages}
          />
        )}
      </Bottom>
    </Container>
  );
}
