import React, { useContext } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { LanguageContext } from './Context';
import { Language } from 'types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useConfig } from 'utils/hooks';

export default function LanguageSelector({
  color,
}: {
  color?: string;
}): JSX.Element {
  const { setLocale, messages, locale, locales, languageId } =
    useContext(LanguageContext);
  const { defaults } = useConfig();
  const history = useHistory();
  const location = useLocation();
  // const { client }: any = useParams();

  const handleChange = (event) => {
    const languageId = event.target.value;
    setLocale(languageId);
    console.log(languageId);
    const client: any = window.sessionStorage.getItem('client');
    if (location?.pathname?.includes('/assets/find'))
      history.push(`/${client}/assets`);
  };

  return (
    <FormControl fullWidth>
      {/* <InputLabel id="language-selector-label">Language</InputLabel> */}
      <div style={{ marginRight: '50px' }}>
        <Select
          labelId="language-selector-label"
          id="language-selector"
          value={(languageId || 1)?.toString()}
          label="Language"
          onChange={handleChange}
          sx={{
            backgroundColor: 'white',
            color: 'black',
            '& .MuiSelect-select': {
              padding: '8px',
            },
            '& fieldset': {
              visibility: 'hidden',
            },
          }} // sx prop can be used for quick inline styles
          displayEmpty
          renderValue={(selected) => {
            // console.log('selected, locales =============> ');
            // console.log(selected, locales, languageId);
            if (!languageId || !locales) return null;
            const selectedLanguage = locales?.find(
              (l) => l.languageid === languageId
            );
            return (
              <>
                <div style={{ display: 'flex' }}>
                  <img
                    src={`https://cdn.weglot.com/flags/rectangle_mat/${
                      selectedLanguage.languageisocode.substring(0, 2) === 'en'
                        ? 'gb'
                        : selectedLanguage.languageisocode.substring(0, 2)
                    }.svg`}
                    alt=""
                    style={{ width: '30px', marginRight: '10px' }}
                  />
                  <strong>
                    {selectedLanguage.languageisocode.toUpperCase()}
                  </strong>
                  {/* {messages[selectedLanguage.languageisocode.substring(0, 2)]} */}
                </div>
              </>
            );
          }}
        >
          {locales?.map((l: Language) => {
            const t = l.languageisocode.substring(0, 2);
            return (
              <MenuItem
                key={t}
                value={l.languageid}
                sx={{
                  padding: '8px',
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor: 'rgb(61, 70, 251)',
                  },
                }}
              >
                <div style={{ display: 'flex' }}>
                  <img
                    src={`https://cdn.weglot.com/flags/rectangle_mat/${
                      l.languageisocode.substring(0, 2) === 'en'
                        ? 'gb'
                        : l.languageisocode.substring(0, 2)
                    }.svg`}
                    alt={`${messages[l.languageisocode.substring(0, 2)]} flag`}
                    style={{ width: '30px', marginRight: '10px' }}
                  />
                  <strong>{l.languageisocode.toUpperCase()}</strong>
                  {/* {messages[t]} */}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </FormControl>
  );
}
