import React from 'react';

type T = {
  user: string | undefined;
  oem: string | undefined;
  setUser: Function;
  closeFooterModal: Function;
  showFooterModal: boolean;
  pdfStr: string | undefined;
  openFooterModal: Function;
  modalType: string | undefined;
  openMyAccount: Function;
  closeMyAccount: Function;
  showAccount: boolean;
  myAccountData: any;
  fromTable: any;
  setNoSubSignin: Function;
  noSubSignin: boolean;
  setLoadComplete: Function;
  loadComplete: boolean;
};

export const AppContext = React.createContext<T>({
  user: '',
  oem: '',
  setUser: () => {},
  closeFooterModal: () => {},
  showFooterModal: false,
  pdfStr: '',
  openFooterModal: () => {},
  modalType: '',
  openMyAccount: () => {},
  closeMyAccount: () => {},
  showAccount: false,
  myAccountData: {},
  fromTable: false,
  setNoSubSignin: () => {},
  noSubSignin: false,
  setLoadComplete: () => {},
  loadComplete: false,
});
