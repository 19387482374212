import { useRecoilState } from 'recoil';
import { useEffect, useCallback, useState } from 'react';
// import { useHistory, useParams } from 'react-router';

import { useToast, useLanguage, useLanguages, useConfig } from 'utils/hooks';
import { useSupabase } from 'utils/supabase';
import { rootState } from './atoms';

const _requests: any = {};

export function useCrud(
  dataState: any,
  table: string,
  parentTable: string | undefined,
  findCb: (d: any, obj: any) => boolean,
  matchCb: Function,
  noRefetch: boolean = false,
  eq?: any,
  what?: any,
  noOEM = false
) {
  const { languageId } = useLanguages();
  const [data, setData] = useRecoilState<any>(dataState as any);
  const supabase = useSupabase();
  const client = window.location.pathname.split('/')[1];
  const { defaults } = useConfig();

  const fetchData = useCallback(async (): Promise<boolean> => {
    try {
      if (!defaults || !defaults.oemid) return true;
      if (_requests[table]) return true;
      _requests[table] = true;
      setData({ status: 'fetching' });
      let p1;
      if (noOEM) p1 = supabase.from(table).select('*');
      else if (parentTable)
        p1 = supabase
          .from(table)
          .selectFilter(
            `*, ${parentTable}!inner(*)`,
            `${parentTable}.oemid`,
            defaults?.oemid
          );
      // Eq('*', 'oemid', defaults?.oemid);
      else if (!eq)
        p1 = supabase.from(table).selectEq('*', 'oemid', defaults?.oemid);
      else p1 = supabase.from(table).selectEq('*', eq, what);
      const result1 = await p1;
      // const filtered = result1?.data?.filter((x) => {
      //   if (!!x?.languageid && x?.langaugeid === languageId) return true;
      //   if (!!x?.languageid && x?.langaugeid !== languageId) return false;
      //   return true;
      // });
      // setData({ ...data, rawData: result1?.data, data: filtered });
      setData({ data: result1?.data });
      console.log('TABLE 1 :::', table);
      _requests[table] = false;
    } catch (e) {
      console.log(e);
      _requests[table] = false;
    }
    return true;
    /* eslint-disable-next-line */
  }, [defaults]);

  // useEffect(() => {
  //   if (_requests[table]) return;
  //   if (!data?.rawData || !languageId) return;
  //   if (data?.languageId === languageId) return;
  //   const filtered = data?.rawData?.slice()?.filter((x) => {
  //     if (!!x?.languageid && x?.langaugeid === languageId) return true;
  //     if (!!x?.languageid && x?.langaugeid !== languageId) return false;
  //     return true;
  //   });
  //   console.log('TABLE 2 ::::', table, filtered, languageId);
  //   setData({ ...data, data: filtered, languageId });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [languageId, data]);

  useEffect(() => {
    // if (noRefetch && data?.data) return;
    // if (data?.[table]?.data !== undefined) return;
    // if (data?.data !== undefined) return;
    // const f = () => {
    //   fetchData();
    // };
    // f();
    /* eslint-disable-next-line */
  }, [client, defaults]);

  const reset = () => {
    setData({ data: undefined, rawData: undefined });
  };

  const updateData = async (d: any) => {
    const r = await supabase.from(table).update(d, matchCb(d));
    return r.data;
  };

  const createData = async (d: any) => {
    const r = await supabase.from(table).insert(d);
    return r.data;
  };

  const deleteData = async (d: any) => {
    await supabase.from(table).delete(matchCb(d));
    await fetchData();
  };

  const deleteAll = async (d: any) => {
    await supabase.from(table).delete(d);
    await fetchData();
  };

  const saveData = async (data: any): Promise<any | null> => {
    const obj = findData(data);
    console.log('save ::::', table, !!obj);
    data.oemid = defaults?.oemid;
    const response = !!obj ? await updateData(data) : await createData(data);
    await fetchData();
    return response;
  };

  const findData = (obj: any) => {
    return data?.data?.find((d) => findCb(d, obj));
  };

  return {
    data: data?.data,
    rawData: data?.rawData,
    saveData,
    deleteData,
    deleteAll,
    reset,
    fetchData,
  };
}

type State = {
  data?: any[];
  ids?: any[];
  maxOrder?: number;
  status: 'fetching' | 'done' | undefined;
  client: string | undefined;
};

export function useData() {
  const [data] = useRecoilState<any>(rootState as any);
  return { data };
}

export function useGeneric(
  dataState: any,
  table: string,
  field: string,
  applyOnSave?: Function,
  applyOnFetch?: Function,
  selectAll: boolean = false,
  noOEM = false,
  noSort = false
) {
  const [data, setData] = useRecoilState<any>(dataState as any);
  const { languages, languageId: defaultLang } = useLanguages();
  const { applyDesc } = useLanguage(languages);
  const { toast } = useToast();
  // const history = useHistory();
  const { defaults } = useConfig();
  const supabase = useSupabase();

  const client = window.location.pathname.split('/')[1];

  const fetchData = useCallback(async () => {
    try {
      // console.log(table, defaults?.oemid, !!_requests?.[table]);
      if (!defaults || !defaults.oemid) return 1;
      if (_requests[table]) return 2;
      _requests[table] = true;
      // console.log('defaults :::::', table, defaults);
      setData({ ...data, status: 'fetching' });
      let p1: any;
      if (!selectAll)
        p1 = supabase
          .from(`${table}_id`)
          .selectEq('*', 'oemid', defaults?.oemid);
      else
        p1 = supabase
          .from(`${table}_id`)
          .selectEq('*', 'oemid', defaults?.oemid);
      const p2 = supabase
        .from(table)
        .selectFilter(
          `*, tb_${field}_id!inner(*)`,
          `${table}_id.oemid`,
          defaults?.oemid
        ); // Eq('*', 'oemid', defaults?.oemid);
      // const p2 = supabase.from(table).selectEq('*', 'oemid', defaults?.oemid); // Eq('*', 'oemid', defaults?.oemid);
      const [result1, result2] = await Promise.all([p1, p2]);
      // console.log('here', table, result1, result2);
      if (!result1.data || !result2.data) {
        reset();
        _requests[table] = false;
        return;
      }
      const max = result1?.data?.reduce(
        (max, rec) => (rec[`${field}order`] > max ? rec[`${field}order`] : max),
        0
      );
      const idData = result1.data; // .filter(({ oemid }) => oemid ? oemid?.toString() === defaults?.oemid?.toString() : true);
      let d = applyDesc(result2.data)?.filter((x) =>
        idData.find(
          (j) => j[`${field}id`]?.toString() === x[`${field}id`]?.toString()
        )
      );
      // console.log({ table, d, idData, defaults });
      if (applyOnFetch) d = applyOnFetch(d, result1.data);
      d = !noSort
        ? d.sort((a: any, b: any) => {
            const sortA = idData.find(
              (x) => x[`${field}id`] === a[`${field}id`]
            );
            const sortB = idData.find(
              (x) => x[`${field}id`] === b[`${field}id`]
            );
            return sortA[`${field}order`] - sortB[`${field}order`];
          })
        : d;
      // console.log('here', table, idData);
      // setData({ ...data, [table]: { ids: idData as any[], data: d, maxOrder: max } });
      setData({ ids: idData as any[], data: d, maxOrder: max });
      _requests[table] = false;
    } catch (e) {
      _requests[table] = false;
      console.error(e);
      // history.push(`/${client}`);
    }
    /* eslint-disable-next-line */
  }, [defaults, client, data]);

  useEffect(() => {
    // if (data?.[table]?.data !== undefined) return;
    // if (data?.data !== undefined) return;
    // const f = () => {
    //   fetchData();
    // };
    // f();
    /* eslint-disable-next-line */
  }, [defaults, client]);

  const reset = () => {
    // setData({ ...data, [table]: { ids: undefined, data: undefined, maxOrder: -1 } });
    setData({ ids: undefined, data: undefined, maxOrder: -1 });
  };

  const updateData = async (data: any): Promise<any | null> => {
    try {
      delete data[`${table}_id`];
      const idData = { ...data }; // { [`${field}id`]: data[`${field}id`], [`${field}order`]: data[`${field}order`] };
      if (applyOnSave) applyOnSave(data, idData);
      if (data.languageid === 1) {
        delete idData.languageid;
        delete idData.languagedescription;
        console.log('id data::::', idData);
        await supabase
          .from(`${table}_id`)
          .update(idData, { [`${field}id`]: data[`${field}id`] });
      }
      delete data['languagedescription'];
      delete data[`${field}order`];
      await supabase.from(table).update(data, {
        [`${field}id`]: data[`${field}id`],
        languageid: data.languageid,
      });
      toast('Update successfull', 'success');
      await fetchData();
      return data;
    } catch (error: any) {
      console.log('Update error', error);
      toast('Update failure', 'error', { error: error.message });
      return null;
    }
  };

  const createData = async (data: any): Promise<any | null> => {
    try {
      delete data[`${table}_id`];
      console.log('create :::', data);
      const idData = { ...data }; // { [`${field}order`]: data[`${field}order`] };
      if (applyOnSave) applyOnSave(data, idData);
      if (!data[`${field}id`] || !findDataId(data[`${field}id`])) {
        delete idData.languageid;
        delete idData.languagedescription;
        let response1: any = await supabase.from(`${table}_id`).insert(idData);
        data[`${field}id`] = response1.data[0][`${field}id`];
        console.log('insert _id :::: ', response1);
      }
      delete data['lanuagedescription'];
      delete data[`${field}order`];
      let response: any = await supabase.from(table).insert(data);
      toast('Create successfull', 'success');
      await fetchData();
      console.log('insert data ::::: ', response);
      return response.data[0];
    } catch (error: any) {
      console.log('Create Error: ', error);
      toast('Create failure', 'error', { error: error.message });
      return null;
    }
  };

  const saveData = (data: any): Promise<any | null> => {
    const obj = findData(data[`${field}id`], data.languageid);
    console.log('save ::::', !!obj);
    data.oemid = defaults?.oemid;
    return !!obj ? updateData(data) : createData(data);
  };

  const deleteData = async (toDelete: any): Promise<boolean> => {
    try {
      await supabase.from(table).delete({
        [`${field}id`]: toDelete[`${field}id`],
        languageid: toDelete.languageid,
      });
      if (
        data?.data?.filter(
          (obj) => obj[`${field}id`] === toDelete[`${field}id`]
        )?.length === 1
      ) {
        await supabase
          .from(`${table}_id`)
          .delete({ [`${field}id`]: toDelete[`${field}id`] });
      }
      toast('Delete successfull', 'success');
      fetchData();
      return true;
    } catch (error: any) {
      console.log('Error: ', error);
      toast('Delete failure', 'error', { error: error.message });
      return false;
    }
  };

  const findData = (id: any, languageId: any) => {
    return data.data?.find(
      (d) =>
        d[`${field}id`]?.toString() === id?.toString() &&
        d.languageid?.toString() === languageId?.toString()
    );
  };

  const findDataId = (id: any) => {
    return data.ids?.find(
      (d) => d[`${field}id`]?.toString() === id?.toString()
    );
  };

  return {
    data: data.data?.filter(({ languageid }) => languageid === defaultLang),
    ids: data.ids,
    setData,
    maxOrder: data.maxOrder,
    fetchData,
    saveData,
    deleteData,
    findData,
    findDataId,
    reset,
    // getAsset
  };
}
