import styled from 'styled-components';

const h3Styles = `
  font-size: 20px;
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2;
  font-family: NeueHaasGroteskDisplay, sans-serif;
  padding-bottom: 4px;
  font-weight: 700 !important;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
`;

const pStyles = `
  font-size: 16px;
  letter-spacing: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: Manrope, sans-serif;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #949494 !important;
  font-weight: 500;
`;

export const Container = styled.div`
  padding: 2em;
  padding-bottom: 5em;
  margin: auto;
  background-color: white;
  color: black;
  font-family: NeueHaasGroteskDisplay, sans-serif !important;
  h3 {
    ${h3Styles}
  }
  p {
    ${pStyles}
  }
`;
