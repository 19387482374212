import styled from 'styled-components';
import TextInput2 from 'components/TextInput2';

export const Label = styled.label`
  color: gray;
`;

export const ContainerSignUp = styled.div<any>`
  margin-top: ${(props) =>
    props.$marginTop ? props.$marginTop : '-3em !important'};
  margin: auto;
  width: 100%;
`;

export const Title = styled.div`
  font-size: '20px';
  color: 'black;';
`;

export const AlignerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 70%;
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Container = styled.div<any>`
  display: flex;
  width: 900px;
  ${(props) =>
    props?.$withAddress
      ? 'align-items: start; margin-top: 1.5rem;'
      : 'align-items: center;'}
`;

export const Input = styled(TextInput2)``;

export const InputGroup = styled.div`
  align-items: center;
  display: flex;
  height: 57.95px;
`;

export const InputGroupIE = styled.div`
  align-items: center;
  display: flex;
  height: 57.95px;
  margin-top: 15px;
`;

export const IconContainer = styled.div`
  height: 80px;
  width: 80px;
`;

export const Image = styled.img`
  height: 100%;
  object-fit: contain;
  padding-bottom: 30px;
`;

export const ImageIE = styled.img`
  height: auto;
  width: 100%;
  padding-bottom: 30px;
`;

export const LeftSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  /* padding: 1rem 0; */
  margin-right: 0.5rem;
`;

export const RightSection = styled.div`
  align-items: center;
  display: flex;
  width: 50%;
  padding: 25px;
`;

export const NoAasra = styled.div`
  ul {
    li {
      list-style-type: circle;
    }
  }
  a {
    color: blue;
    padding: 0.5rem 0;
  }
`;

export const GuideImg = styled.img`
  margin: 1em 0 0 0;
  width: 300px;
  /* background: #000; */
  padding: 2rem;
  border-radius: 5px;
`;

import {
  ButtonHover,
  ButtonColorWhite,
} from 'styled';
import isIE from 'utils/isIE';

export const LoginButton = styled.button`
  padding: 14px 8px;
  border: none;
  background: ${() =>
    isIE()
      ? '#2d2c2c'
      : (props) => (props.disabled ? 'grey' : ButtonColorWhite.toString())};
  transition: all 0.2s;
  background-image: linear-gradient(86deg, #356fcb, #2d99d7);
  background-color: #2d2d2d;
  background-image: linear-gradient(86deg, #356fcb, #2d99d7);
  color: #fbfaf9;
  border-radius: 10px;
  padding: 12px 24px;
  font-weight: 500;
  line-height: 20px;
  transition: box-shadow 0.4s, color 0.35s;
  box-shadow: 0 12px 24px -6px rgba(45, 32, 17, 0.2),
    0 3px 7px rgba(0, 0, 0, 0.2);

  :active {
    background: ${ButtonHover.lighten(0.3).string()};
  }

  :hover {
    background-image: linear-gradient(to bottom, #356fcb, #356fcb);
    opacity: 1;
    color: #fff;
    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.25);
  }

  :disabled {
    background: ${ButtonHover.lighten(0.9).string()};
  }
`;
