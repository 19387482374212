import styled from 'styled-components';
import { Link as RRLink } from 'react-router-dom';

export const Link = styled(RRLink)<any>`
  margin-left: 10px;
  font-weight: 600;
  font-family: NeueHaasGroteskDisplay, sans-serif;
  line-height: 1;
  letter-spacing: 1px;
  color: ${(props) =>
    props.$client === 'mmal' ? '#4a4340' : props.$data?.fontcolor || '#4a4340'};
  :hover {
    //color: ${(props) => props.$data?.primarycolour || 'white'};
    color: #205fa6;
  }
`;
