import styled from 'styled-components';
import Color from 'color';

import isIE from 'utils/isIE';

export const LinkColor = isIE()
  ? { lighten: () => ({ string: () => '#5a646e' }) }
  : Color('#5a646e');

export const ButtonColor = isIE()
  ? { lighten: () => ({ string: () => '#343333' }) }
  : Color('#343333');

export const ButtonColorWhite = isIE()
  ? { lighten: () => ({ string: () => '#fff' }) }
  : Color('#fff');

export const ButtonColorWhiteHover = isIE()
  ? { lighten: () => ({ string: () => 'blue' }) }
  : Color('#bababa');

export const ButtonHover = isIE()
  ? { lighten: () => ({ string: () => '#343333' }) }
  : Color('#343333');

export const DarkBGColor = isIE()
  ? { lighten: () => ({ string: () => '#343333' }) }
  : Color('#343333');

export const Padded = styled.span`
  display: inline-block;
  padding: 1em;
`;
