function HondaLaunchContent() {
  return (
    <article role="article" style={{ padding: '2em' }}>
      <div>
        <div>
          <section className="body-content d-flex flex-column">
            <div className="home-page">
              <p>
                <strong>Background</strong>
              </p>
              <br />
              <p>
                Honda Australia Pty Ltd <strong>(Honda Australia)</strong> is
                the exclusive importer and distributor of new Honda branded
                motor vehicles in Australia. As such, Honda Australia is deemed
                to be a data provider for the purposes of the Motor Vehicle
                Information Scheme <strong>(MVIS)</strong> which came into
                effect as of 1 July 2022.
              </p>
              <br />
              <p>
                Honda Australia primarily seeks to meet its obligations under
                the MVIS via this website.
              </p>
              <br />
              <p>
                The MVIS aims to promote competition in the Australian
                automotive sector and establish a level playing field for all
                repairers. It is designed to allow consumers to have greater
                choice of repairers who are able to safely and effectively
                repair their vehicles.
              </p>
              <br />
              <p>
                The scheme requires data providers to make motor vehicle service
                and repair information available for purchase at a fair market
                price.
              </p>
              <br />
              <p>
                The following Honda information is provided on this website for
                passenger and light goods vehicles (this includes cars, vans and
                utility vehicles) manufactured from 1 January 2002 onwards:
                workshop manuals, service schedules, technical bulletins, body
                repair manuals & diagnostic software. Technical Information
                provided on this site applies to vehicles imported & sold by
                Honda Australia Pty. Ltd for the Australian market only.
              </p>
              <br />
              <p>
                For further information, please refer to the Terms & Conditions
                at the bottom of this page.
              </p>
              <br />
              <h3>
                <strong>Who can access scheme information?</strong>
              </h3>
              <br />
              <p>
                The scheme information is available to purchase by all repairers
                that diagnose, repair, service, modify or dismantle a scheme
                vehicle (manufactured from 1 January 2002 onwards) as well as
                registered training organisations <strong>(RTOs)</strong>{' '}
                providing motor vehicle training in Australia.
              </p>
              <br />
              <p>
                Information related to safety and security is restricted & will
                only be available to individuals RTOs with the relevant
                documentation & qualifications, having a Fit & Proper Person
                check. Please refer to The Australian Automotive Service and
                Repair Authority <strong>(AASRA)</strong> website{' '}
                <a
                  href="https://aasra.com.au/"
                  style={{ textDecoration: 'underline' }}
                  target="_blank"
                >
                  <strong>(https://aasra.com.au/)</strong>
                </a>
              </p>
              <br />
              <h3 style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Pricing
              </h3>

              <table
                style={{
                  borderCollapse: 'collapse',
                  border: 'none',
                  marginBottom: '1.2em',
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #D9D9D9',
                      }}
                    >
                      <p style={{ marginBottom: '1.8em' }}>
                        <strong>
                          <span>Subscription Duration</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        // borderLeft: 'none',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #D9D9D9',
                        borderRight: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1.8em' }}>
                        <strong>
                          <span>Cost - (Excluding GST) in AUD</span>
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #D9D9D9',
                      }}
                    >
                      <p style={{ marginBottom: '1.8em' }}>
                        1 Year (per premises){' '}
                      </p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        // borderLeft: 'none',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #D9D9D9',
                        borderRight: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1.8em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3380.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #D9D9D9',
                      }}
                    >
                      <p style={{ marginBottom: '1.8em' }}>
                        1 Month (per premises){' '}
                      </p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        // borderLeft: 'none',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #D9D9D9',
                        borderRight: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1.8em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 390.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #D9D9D9',
                      }}
                    >
                      <p style={{ marginBottom: '1.8em' }}>
                        1 Week (per premises)
                      </p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #D9D9D9',
                        borderRight: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1.8em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 65.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid  #D9D9D9',
                      }}
                    >
                      <p style={{ marginBottom: '1.8em' }}>
                        1 Day (per premises)
                      </p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #D9D9D9',
                        borderRight: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1.8em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 20.00
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1.0px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid  #D9D9D9',
                        borderBottom: 0,
                      }}
                    >
                      <p style={{ marginBottom: '1.8em' }}>
                        1 Year Access to Diagnostic Software (per premises)
                      </p>
                    </td>
                    <td
                      style={{
                        width: '280px',
                        border: 'solid 1px',
                        padding: '0 5.4px',
                        height: '14.25px',
                        borderTop: 0,
                        borderLeft: '4px solid #D9D9D9',
                        borderRight: 0,
                        borderBottom: 0,
                      }}
                    >
                      <p style={{ marginBottom: '3em' }}>
                        $ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 900.00
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
              <p>
                A 12-month Honda diagnostic subscription allows the user to
                download the diagnostic software. Refer to &nbsp;
                <a
                  target="_blank"
                  href="https://aasra.com.au/wp-content/uploads/2022/06/Honda-diagnostic-information.pdf"
                >
                  https://aasra.com.au/wp-content/uploads/2022/06/Honda-diagnostic-information.pdf
                </a>
                &nbsp; for PC/laptop and supported operating system
                requirements. Note: some tasks such as diagnostics, ECU
                programming, and security coding will require additional
                hardware which is the responsibility of users to arrange. The
                subscriber will be notified of available updates for the
                duration of the 12-month subscription. After expiration of the
                subscription, no further updates will be provided.
              </p>
              <br />
              <br />

              <h3>
                <strong>Contact</strong>
              </h3>
              <br />
              <p>
                If you have any questions regarding this website or your
                subscription, email us at{' '}
                <a
                  href="mailto:honda.support@right-2-repair.com.au"
                  style={{ textDecoration: 'underline' }}
                >
                  <strong>honda.support@right-2-repair.com.au.</strong>
                </a>
              </p>
            </div>
          </section>
        </div>
      </div>
    </article>
  );
}

export default HondaLaunchContent;
