import { supabase } from 'utils/supabase';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { atom } from 'recoil';

import { useGeneric, useCrud } from 'utils/genericData';
import { useOEMUserList, useOEMList } from 'features/page/hooks';
import { GAP } from 'types';
import { useConfig } from 'utils/hooks';

type State = {
  data?: any[];
  ids?: any[];
  maxOrder?: number;
};

const initialState: State = {
  data: undefined,
  ids: undefined,
  maxOrder: undefined,
};

const UserAccessState = atom({
  key: 'accessUsers',
  default: initialState,
});

export function useUserAccess(email: string) {
  // const xport = useGeneric(UserAccessState, 'tb_user_access', 'useremailid');
  const xport = useCrud(
    UserAccessState,
    'tb_user_access',
    undefined,
    (left: any, right: any) =>
      left.accessid === right.accessid &&
      left.useremailid === right.useremailid,
    (d: any) => ({ accessid: d.accessid, useremailid: d.useremailid }),
    undefined,
    'useremailid',
    email
  );

  return xport;
}

const UserAasaraState = atom({
  key: 'aasraAccessUsers',
  default: initialState,
});

export function useUserAasra(email) {
  // const xport = useGeneric(UserAccessState, 'tb_user_access', 'useremailid');
  const xport = useCrud(
    UserAasaraState,
    'tb_user_aasraprofiles',
    undefined,
    (left: any, right: any) =>
      left.aasraprofileid === right.aasraprofileid &&
      left.useremailid === right.useremailid,
    (d: any) => ({
      aasraprofileid: d.aasraprofileid,
      useremailid: d.useremailid,
    }),
    undefined,
    'useremailid',
    email
  );

  return xport;
}

export function useOEM() {
  const { hasOemAdmin } = useOEMUserList();
  const [status, setStatus] = useState<string>();
  const [gapOems, setgapOems] = useState<any[]>();
  const [gaps, setgaps] = useState<any[]>();
  const [users, setUsers] = useState<any[]>();
  const [subs, setSubs] = useState<any[]>();
  const sbase = supabase();
  const { client }: any = useParams();
  const { defaults } = useConfig();

  const fetchDataGapOems = async () => {
    if (!hasOemAdmin) return;
    try {
      const data: any = await sbase
        .from('tb_gap_oem')
        .select('*')
        .match({ oemid: defaults?.oemid })
        .throwOnError();
      const subs: any = await sbase
        .from('tb_gap_subscriptions')
        .select('*')
        .eq('oemid', defaults?.oemid)
        .neq('test_subscriptions', true);
      setgapOems(
        data.data.filter((x) => subs?.data?.find((y) => y?.gapid === x.gapid))
      );
    } catch (e) {
      console.log('error ::::', e);
    }
  };

  const fetchDataGaps = async () => {
    if (!hasOemAdmin) return;
    try {
      const data: any = await sbase
        .from('tb_gap')
        .select('*, tb_gap_oem!inner(*)')
        .eq('tb_gap_oem.oemid', defaults?.oemid)
        .throwOnError();
      setgaps(data?.data?.filter((x) => !x.isinternal));
    } catch (e) {
      console.log('error ::::', e);
    }
  };

  const fetchDataSubs = async () => {
    if (!hasOemAdmin) return;
    try {
      const data: any = await sbase
        .from('tb_gap_subscriptions')
        .select('*')
        .eq('oemid', defaults?.oemid)
        .throwOnError();
      setSubs(data?.data);
    } catch (e) {
      console.log('error ::::', e);
    }
  };

  const fetchDataUsers = useCallback(
    async (oemid?: number) => {
      console.log('OEM USERS ::::', hasOemAdmin, oemid);
      if (!hasOemAdmin) return;
      try {
        console.log(oemid, defaults);
        const data: any = await sbase
          .from('tb_oem_userlist')
          .select('*')
          .eq('oemid', oemid ?? defaults?.oemid)
          .throwOnError();
        setUsers(data.data);
        // console.log('HERE :::::', data.data);
      } catch (e) {
        console.log('error ::::', e);
      }
    },
    [defaults, hasOemAdmin, sbase]
  );

  const updateGapOem = async (gapid: number, isvalid: boolean) => {
    if (!hasOemAdmin) return;
    try {
      await sbase
        .from('tb_gap_oem')
        .update({ isvalid })
        .match({ gapid, oemid: defaults?.oemid })
        .throwOnError();
      fetchDataGapOems();
    } catch (e) {
      console.log('error ::::', e);
    }
  };

  const hasUser = (email: string): boolean => {
    if (!email) return false;
    const has = !!users?.find(({ id }) => id === email);
    return has;
  };

  const updateOemUser = useCallback(
    async (useremailid: string, props: any, oemid: number) => {
      // console.log(defaults?);
      if (!hasOemAdmin) return 'no admin';
      if (!defaults?.oemid && !oemid) return 'missing oemid';
      try {
        setStatus('inprogress');
        await sbase
          .from('tb_oem_userlist')
          .update({ ...props })
          .match({ oemid: defaults?.oemid || oemid, id: useremailid })
          .throwOnError();
        fetchDataUsers(oemid ?? defaults?.oemid);
      } catch (e: any) {
        console.log('error ::::', e);
        return e.message;
      }
      setStatus('');
      return null;
    },
    [defaults, hasOemAdmin, fetchDataUsers, sbase]
  );

  const userExists = async (email: string) => {
    const u: any = await sbase
      .from('tb_users')
      .select('*')
      .eq('useremailid', email);
    if (!u?.data?.length) return false;
    return true;
  };

  const addUser = async (user: any, valid: any) => {
    if (!hasOemAdmin) return;
    try {
      setStatus('inprogress');
      const r: any = hasUser(user.userid);
      // const exists = await userExists(user.userid);
      if (valid.reg) return 'Already registered';
      if (!valid.exists) {
        const r = await sbase.auth.api.signUpWithEmail(
          user.userid,
          user.password
        );
        if (r.error) {
          setStatus('');
          return r.error.message;
        }
        const u = {
          useremailid: user.userid,
          // mobilenumber: user.mobile,
          firstname: user.firstName,
          surname: user.lastName,
        };
        await sbase.from('tb_users').insert(u).throwOnError();
      }
      await sbase
        .from('tb_oem_userlist')
        .insert({
          oemid: defaults?.oemid,
          id: user.userid,
          isactive: user.isvalid,
        })
        .throwOnError();
      fetchDataUsers(defaults?.oemid);
      setStatus('');
      return null;
    } catch (e: any) {
      console.log('error ::::', e);
      setStatus('');
      return e.message;
    }
  };

  return {
    fetchDataGapOems,
    fetchDataUsers,
    fetchDataGaps,
    fetchDataSubs,
    gaps,
    subs,
    status,
    users,
    gapOems,
    updateGapOem,
    updateOemUser,
    hasUser,
    addUser,
    userExists,
  };
}

export default {};
