import { memo, useEffect } from 'react';

import * as S from './styled';

export default memo(function Modal({
  children,
  close,
  closeXPosition,
  closeYPosition,
  overflow,
  style,
  height,
  $bgcolor,
}: {
  children: any;
  close: any;
  closeXPosition?: string;
  closeYPosition?: string;
  overflow?: string;
  style?: any;
  height?: string;
  $bgcolor?: string;
}) {
  // console.log({ overflow, style });
  useEffect(() => {
    const handler: any = window.addEventListener('keydown', (e) => {
      e?.stopPropagation?.();
      if (e?.key?.toLowerCase() === 'escape') {
        close();
      }
    });

    return () => {
      window.removeEventListener('keydown', handler);
    };
  });
  return (
    <>
      <S.Backdrop></S.Backdrop>
      <S.ModalContainer
        $overflow={overflow}
        $height={height}
        style={style}
        $bgcolor={$bgcolor}
      >
        <S.Close
          $xPosition={closeXPosition}
          $yPosition={closeYPosition}
          onClick={close}
        >
          <span
            className="fas fa-times text-gray-500"
            style={{ display: 'inline-block', fontSize: 25 }}
          ></span>
        </S.Close>
        {children}
      </S.ModalContainer>
    </>
  );
});
