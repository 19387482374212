import { APIResponse, GAP, User, UserData } from 'types';
import { StringLiteralLike } from 'typescript';

// const host = window.location.host;
const host = process.env.REACT_APP_HOST;

let url = '';
url = `${process.env.REACT_APP_HOST}.netlify/functions`;

export const urlApi =
  process.env.REACT_APP_LOCAL === 'func'
    ? url
    : process.env.REACT_APP_LOCAL === 'local'
    ? 'http://localhost:3010'
    : host?.includes('dev')
    ? 'https://api.b-on.online:8080'
    : 'https://api.b-on.online:8080';
// ? 'https://api.right-2-repair.com.au:8080'
// : 'https://api.right-2-repair.com.au';

const getAuths = () => {
  const client = window.location.pathname.split('/')[1];
  const auth: any = sessionStorage.getItem('auth');
  const store = JSON.parse(auth || '{}');
  return {
    email: store?.user?.email,
    gapid: store?.user?.gapid,
    token: store?.token,
    client,
    host,
    oemid: store?.oemid,
  };
};

const fetchApi = async (path: string, opts: any) => {
  if (process.env.REACT_APP_LOCAL === 'func') {
    delete opts.headers;
  }
  return fetch(path, opts);
};

export async function uatMailer(
  email: string,
  client: string,
  content: string,
  URLtoRedirect: string
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/uatMailer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...auths,
      URLtoRedirect,
    }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function userFetch(
  email: string | undefined,
  mobile?: string,
  gapid?: number
): Promise<APIResponse> {
  const client = window.location.pathname.split('/')[1];
  const resp = await fetchApi(`${urlApi}/user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, mobile, host, client, gapid }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function accessFetch(
  email: string,
  client: string,
  oemid: number,
  gapid?: number
): Promise<APIResponse> {
  const resp = await fetchApi(`${urlApi}/access`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, client, oemid, host, gapid }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function userPost(
  user: UserData,
  type: string,
  aasraRoles: string
  // gaps: number[],
  // gap?: GAP
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/userCreate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, user, type, aasraRoles }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function gapCreate(
  gap: any,
  gapid: number | undefined,
  oemid: number,
  email?: string
): Promise<APIResponse> {
  const resp = await fetchApi(`${urlApi}/gapCreate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ gap, gapid, oemid, host, email }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function logoutSession(): Promise<APIResponse> {
  const client = window.location.pathname.split('/')[1];
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...auths,
    }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function session(
  email: string,
  token: string,
  gapid: number,
  subscription: any[],
  accesstoken: string
): Promise<APIResponse> {
  const client = window.location.pathname.split('/')[1];
  const resp = await fetchApi(`${urlApi}/session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      token,
      client,
      host,
      gapid,
      subscription,
      accesstoken,
    }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function account(
  email: string,
  token: string
): Promise<APIResponse> {
  const client = window.location.pathname.split('/')[1];
  const resp = await fetchApi(`${urlApi}/account`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, token, host, client }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function sessionValidate(
  email: string,
  token: string
): Promise<APIResponse> {
  const client = window.location.pathname.split('/')[1];
  const resp = await fetchApi(`${urlApi}/sessionValidate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, token, host, client }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

let _oemUserList = 'done';

export async function oemUserList(
  email: string,
  oemid: number,
  gapid: number
): Promise<APIResponse | null> {
  if (_oemUserList === 'fetching') return null;
  _oemUserList = 'fetching';
  const resp = await fetchApi(`${urlApi}/oemUserList`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, oemid, host, gapid }),
  });
  const data = await resp.json();
  _oemUserList = 'done';
  return data as APIResponse;
}

export async function oemUserListIns(
  email: string,
  oems: any[]
): Promise<APIResponse> {
  const resp = await fetchApi(`${urlApi}/oemUserList`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, oems, host }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function aasraLogin(
  loginType: 'standard' | '2fa',
  oemid: number,
  email: string,
  password: string,
  otp?: string,
  noOkta?: boolean
): Promise<APIResponse> {
  // const client = window.location.pathname.split('/')[1];
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/aasraOEMLogin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...auths,
      email,
      loginType,
      password,
      otp,
      host,
      oemid,
      noOkta,
    }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function requestCode(
  vin: string,
  hasapproval: boolean,
  type: string
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/requestCode`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, vin, hasapproval, type }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function requestCodeResponse(
  code: string,
  hasapproval: boolean,
  rtoken: string
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/requestCodeResponse`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...auths,
      rtoken,
      code,
      hasapproval,
      type: 'response',
    }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function fetchRequestDetails(
  rtoken: string
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/requestCodeDetails`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, rtoken, type: 'details' }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function clipEmail(): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/clipEmail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function otpValidate(
  email: string,
  code: string
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/otpValidate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, email, code }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function otpResend(
  mobile: string,
  email: string
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/otpResend`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, mobile, email }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function awsFile(path: string): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/awsBucketDownloader`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, path }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function reqSWGetSub(oemid: number): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/reqSWGetSub`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, oemid }),
  });
  const data = await resp.json();
  return data as APIResponse;
}

export async function requestSoftware(data: any): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/requestSoftware`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, ...data }),
  });
  const r = await resp.json();
  return r as APIResponse;
}

export async function userValidTo(
  data: any,
  which: any,
  onlyindividual: boolean,
  days: number
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/userValidTo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, which, onlyindividual, user: data, days }),
  });
  const r = await resp.json();
  return r as APIResponse;
}

export async function userReactivate(userEmail: string): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/userReactivate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, userEmail }),
  });
  const r = await resp.json();
  return r as APIResponse;
}

export async function userUpdateMobile(
  userEmail: string,
  mobile: string
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/userUpdateMobile`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, userEmail, mobile }),
  });
  const r = await resp.json();
  return r as APIResponse;
}

export async function redirectTokenFetch(
  email: string,
  token: string
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/redirectToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, email, token }),
  });
  const r = await resp.json();
  return r as APIResponse;
}

export async function supportGapsFetch(): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/supportGapsFetch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths }),
  });
  const r = await resp.json();
  return r as APIResponse;
}

export async function supportGapsUpdateABN(
  gapid: number,
  abn: string | undefined,
  otherData: any = undefined
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/supportGapsUpdateABN`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, gapid, abn, otherData }),
  });
  const r = await resp.json();
  return r as APIResponse;
}

export async function supportGapsDelete(gapid: number): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/supportGapsDelete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, gapid }),
  });
  const r = await resp.json();
  return r as APIResponse;
}

export async function updatePassword(
  userEmail: string,
  newPwd: string
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/updatePassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, userEmail, newPwd }),
  });
  const r = await resp.json();
  return r as APIResponse;
}

export async function supportTransferGAP(
  fromGapID: number,
  toGapID: number
): Promise<APIResponse> {
  const auths = getAuths();
  const resp = await fetchApi(`${urlApi}/supportTransferGAP`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...auths, fromGapID, toGapID }),
  });
  const r = await resp.json();
  return r as APIResponse;
}

export async function validateNewUser(email: string): Promise<APIResponse> {
  const resp = await fetchApi(`${urlApi}/validateNewUser`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, host: 'subaru' }),
  });
  const r = await resp.json();
  return r as APIResponse;
}
