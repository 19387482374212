import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { ThemeContext } from 'components/Theme/Context';
import * as S from './styled';
import { useConfig } from 'utils/hooks';

type Props = {
  messageId: string;
  onClick?: Function;
  to?: string;
  alt?: boolean;
};

function LinkButton({ messageId, onClick, to = '', alt = false }: Props) {
  // const { theme } = useContext(ThemeContext);
  const { defaults: theme } = useConfig();

  return onClick ? (
    <S.LinkButtonS
      type="button"
      theme={theme}
      alty={alt ? 'y' : 'n'}
      onClick={onClick as any}
    >
      <FormattedMessage id={messageId} />
    </S.LinkButtonS>
  ) : (
    <S.StyledLink theme={theme} alty={alt ? 'y' : 'n'} to={to}>
      <FormattedMessage id={messageId} />
    </S.StyledLink>
  );
}

export function LinkButtonDark({
  messageId,
  onClick,
  to = '',
  alt = false,
}: Props) {
  // const { theme } = useContext(ThemeContext);
  const { defaults: theme } = useConfig();

  return onClick ? (
    <S.LinkButtonS
      type="button"
      theme={theme}
      alty={alt ? 'y' : 'n'}
      onClick={onClick as any}
      style={{
        color: '#356fcb',
        fontWeight: 600,
        letterSpacing: '1px',
        fontFamily: 'NeueHaasGroteskDisplay, sans-serif',
        fontSize: '1.2rem',
      }}
    >
      <FormattedMessage id={messageId} />
    </S.LinkButtonS>
  ) : (
    <S.StyledLink theme={theme} alty={alt ? 'y' : 'n'} to={to}>
      <FormattedMessage id={messageId} />
    </S.StyledLink>
  );
}
export default LinkButton;
