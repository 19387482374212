import styled from 'styled-components';
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import R2RSignupModal from 'features/R2RSignupModal';
import AsyncButton from 'components/AsyncButton';

import { useLoggedIn, useLanguages } from 'utils/hooks';
import logo from 'assets/r2r_logo_new.png';
import Menu from '../Menu';

const Head = styled.header`
  font-family: 'mmcbold';
  .link {
    a {
      color: white;
    }
  }
`;

const Links = styled.div`
  a {
    color: white;
  }
`;

const LogoImage = styled.img<any>`
  display: block;
  height: 80px;
  width: auto;
`;

type Props = {
  user?: string;
};

function Header() {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const { messages } = useLanguages();

  useEffect(() => {
    // const fav: any = document.getElementById('favicon');
    // fav.href = '/favicon.ico';
  }, []);

  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <Head id="r2r-header" className="bg-black w-full flex p-3 text-white">
      <div className="flex justify-start w-full pl-8 align-center">
        <LogoImage src={logo} alt="Workflow" />
        {/* <div
          style={{ whiteSpace: 'nowrap' }}
          className="grow text-2xl pl-2 flex self-center w-200"
        >
          Right-2-Repair
        </div> */}
        <Links className="flex justify-end w-full items-center pr-3">
          <span
            className="text-center text-xxs font-normal font-sans mr-10 link"
            style={{ fontSize: '16px', fontFamily: 'mmcregular' }}
          >
            <Link to="/">{messages.home}</Link>
          </span>
          <span
            className="text-center text-xxs font-normal font-sans mr-10 link"
            style={{
              fontSize: '16px',
              fontFamily: 'mmcregular',
              cursor: 'pointer',
            }}
          >
            <div
              onClick={() => {
                setShowLoginModal(true);
              }}
            >
              Signup
            </div>
            {/* <Link to="/auth/signup">{messages.signup}</Link> */}
            {showLoginModal && (
              <>
                {/* <AsyncButton type="submit" /> */}
                <R2RSignupModal
                  client={''}
                  page={1}
                  image={''}
                  close={() => setShowLoginModal(false)}
                />
              </>
            )}
          </span>
          <button className="p-3 m4" onClick={() => toggleMenu()}>
            <span className="fas fa-bars" />
          </button>
        </Links>
      </div>
      <Menu showMenu={showMenu} setShowMenu={toggleMenu} messages={messages} />
    </Head>
  );
}

export default Header;
