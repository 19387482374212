import styled from 'styled-components';
import {
  ButtonHover,
  ButtonColorWhite,
} from 'styled';
import isIE from 'utils/isIE';

export const LoginButton = styled.button`
  padding: 14px 8px;
  border: none;
  background: ${() =>
    isIE()
      ? '#2d2c2c'
      : (props) => (props.disabled ? 'grey' : ButtonColorWhite.toString())};

  background-image: linear-gradient(86deg, #356fcb, #2d99d7);
  background-color: #2d2d2d;
  background-image: linear-gradient(86deg, #356fcb, #2d99d7);
  color: #fbfaf9;
  border-radius: 10px;
  padding: 12px 24px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 20px;
  transition: box-shadow 0.4s, color 0.35s;
  box-shadow: 0 12px 24px -6px rgba(45, 32, 17, 0.2),
    0 3px 7px rgba(0, 0, 0, 0.2);
  :active {
    background-image: linear-gradient(to bottom, #356fcb, #356fcb);
  }

  :hover {
    background-image: linear-gradient(to bottom, #356fcb, #356fcb);
    opacity: 1;
    color: #fff;
    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.25);
  }

  :disabled {
    background: ${ButtonHover.lighten(0.9).string()};
  }
`;
