import { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { LanguageContext } from './Context';
import { useSupabase } from 'utils/supabase';
import { Language } from 'types';

import en from './langs/en.json';
import es from './langs/es.json';
import fi from './langs/fi.json';
import nl from './langs/nl.json';
import no from './langs/no.json';
import pt from './langs/pt.json';
import sv from './langs/sv.json';

const definitions = {
  en,
  es,
  fi,
  nl,
  no,
  pt,
  sv,
};

const defaultLocale = window?.sessionStorage.getItem('locale') || 'en';

let isFetching = false;

export default function LanguageProvider({ children }): JSX.Element {
  const [locale, setLocale] = useState<string>(defaultLocale);
  const [locales, setLocales] = useState<Language[]>();
  const [languageId, setLanguageId] = useState<any>();
  const supabase = useSupabase();

  useEffect(() => {
    if (locales || isFetching) return;
    //   {
    //     "languageisocode": "en-AU",
    //     "languagedescription": "English (Australia)",
    //     "languageimageurl": "https://picture10689224.files.wordpress.com/2021/12/no_image.png",
    //     "languageimageurlthumbnail": "https://picture10689224.files.wordpress.com/2021/12/no_image.png",
    //     "languageorder": 1,
    //     "languageid": 1
    // }
    if (
      window.document.location.hostname.includes('.dev.') ||
      window.document.location.hostname.includes('localhostd')
    ) {
      isFetching = true;
      const data: any = [
        {
          languageisocode: 'en',
          languagedescription: 'English',
          languageimageurl:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageimageurlthumbnail:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageorder: 1,
          languageid: 1,
        },
        {
          languageisocode: 'es',
          languagedescription: 'Spainish',
          languageimageurl:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageimageurlthumbnail:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageorder: 2,
          languageid: 2,
        },
        {
          languageisocode: 'nl',
          languagedescription: 'Dutch',
          languageimageurl:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageimageurlthumbnail:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageorder: 3,
          languageid: 3,
        },
        {
          languageisocode: 'fi',
          languagedescription: 'Finish',
          languageimageurl:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageimageurlthumbnail:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageorder: 4,
          languageid: 4,
        },
        {
          languageisocode: 'pt',
          languagedescription: 'Portugese',
          languageimageurl:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageimageurlthumbnail:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageorder: 5,
          languageid: 5,
        },
        {
          languageisocode: 'sv',
          languagedescription: 'Swedish',
          languageimageurl:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageimageurlthumbnail:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageorder: 6,
          languageid: 6,
        },
        {
          languageisocode: 'no',
          languagedescription: 'Norwanian',
          languageimageurl:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageimageurlthumbnail:
            'https://picture10689224.files.wordpress.com/2021/12/no_image.png',
          languageorder: 7,
          languageid: 7,
        },
      ];
      setLocales(data);
      isFetching = false;
    } else {
      const f = async () => {
        isFetching = true;
        const data: any = await supabase?.from('tb_language_id')?.select('*');
        console.log('DATA FOR LANGUAGES ================> ');
        console.log(data);
        data.data.sort((a, b) => a.languageorder - b.languageorder);
        setLocales(data.data);
        isFetching = false;
      };
      f();
    }
  }, [locales, supabase]);

  useEffect(() => {
    const loc: Language | undefined = locales?.find(({ languageisocode }) =>
      languageisocode.includes(locale)
    );
    setLanguageId(loc?.languageid);
  }, [locale, locales]);

  useEffect(() => {
    if (!locales) return;
    const srch = window.location.search; // href.split('?')?.[1]; // location?.search;
    if (!srch) return;
    const urlParams = new URLSearchParams(srch);
    let lang = urlParams.get('language');
    // console.log({ language: lang, srch });
    if (lang) {
      const loc: Language | undefined = locales?.find(
        ({ languageisocode }) => languageisocode === lang?.toLowerCase()
      );
      console.log('language', loc);
      updateLocale(loc ? loc?.languageid?.toString() : '1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locales]);

  const updateLocale = (l: string) => {
    const loc: Language | undefined = locales?.find(
      ({ languageid }) => languageid.toString() === l?.toString()
    );
    if (!loc) return;
    const iso = loc.languageisocode.substring(0, 2);
    window?.sessionStorage.setItem('locale', iso || '');
    setLocale(iso);
    setLanguageId(loc.languageid);
  };

  return (
    <LanguageContext.Provider
      value={{
        setLocale: updateLocale,
        locale,
        messages: definitions[locale],
        locales,
        languageId,
      }}
    >
      <IntlProvider
        messages={definitions[locale]}
        locale={locale}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}
