import styled from 'styled-components';
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Menu from '../Menu';
import { useHistory } from 'react-router';
import LoginModal from 'features/LoginModal';
import SignupModal from 'features/R2RSignupModal';
import { LanguageContext } from 'components/Language/Context';
import { useLoggedIn, useConfig } from 'utils/hooks';
import { OEMList } from 'types';
import NavMenu from './Menu';

import logo from 'assets/r2r_logo_new.png';

const LoginLink = styled(Link)<{ $data: OEMList }>`
  color: ${(props) => props.$data.fontcolour};
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
`;

const StyledStrong = styled.strong`
  color: #4a4340;
  font-family: NeueHaasGroteskDisplay, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 1.5;
  transition: 0.1s ease-in;
  &:hover {
    color: #205fa6;
  }
`;

const Head = styled.header<{ $data: OEMList }>`
  width: 80%;
  margin: 20px auto;
  padding: 0;
  background-color: transparent;
  border-radius: 60px;
  box-shadow: rgb(24, 30, 58) 0px 3px 14px -8px;
  font-family: NeueHaasGroteskDisplay, sans-serif;
`;

const LogoContainer = styled.div<any>`
  //align-items: center;
`;

const LogoImage = styled.img<any>`
  align-self: center;
  display: block;
  height: 45px;
  width: auto;
  cursor: pointer;
`;

const LogoName = styled.div<any>`
  line-height: 4.85rem;
  font-size: 25px;
  font-family: mmcbold;
  padding-left: 1rem;
`;

const LogoName2 = styled.div<any>`
  line-height: 4.85rem;
  align-self: center;
  display: block;
  font-weight: 600;
  font-size: 18px;
  padding-left: 1rem;
  font-family: NeueHaasGroteskDisplay, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.5rem;
  padding-top: 5px;
`;

const MenuContainer = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 2rem;
`;

const MenuWrapper = styled.div<any>`
  white-space: nowrap;
  font-size: 1rem;
  line-height: 20px;
  font-weight: 600;
  font-family: NeueHaasGroteskDisplay, sans-serif;
  color: ${(props) => props.$defaults?.fontcolour};
  a,
  button {
    color: ${(props) => props.$defaults?.fontcolour};
    letter-spacing: 1px;
  }
  span.nav-active > a {
    color: #205fa6 !important;
  }
  a:hover,
  button:hover {
    color: #205fa6 !important;
  }
`;

const ALink = styled<any>(Link)`
  color: ${(props) => {
    return '#94969c';
    // return props.$defaults?.fontcolour || '#94969c';
  }};
  // color: '#94969c';
  font-size: 100px;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  font-family: NeueHaasGroteskDisplay, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 150%;
`;

function Generic() {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showSignupModal, setShowSignupModal] = useState<boolean>(false);
  const { user: authUser } = useLoggedIn(true, true);
  const { messages } = useContext(LanguageContext);
  const { defaults } = useConfig();

  const history = useHistory();

  const client = window.location.pathname.split('/')[1];

  useEffect(() => {
    if (!defaults) return;
    const fav: any = document.getElementById('favicon');
    console.log('FAVICON', defaults);
    if (defaults?.faviconurl && client) fav.href = defaults?.faviconurl;
    // else fav.href = '/public/favicon.ico';
  }, [defaults, client]);

  // console.log('MENU :::::', defaults);
  // if (!defaults) return null;

  useEffect(() => {
    if (!authUser?.email) setShowMenu(false);
  }, [authUser?.email]);

  const navigationHome = async (path?: string) => {
    history.push(`/${client}`);
  };

  const toggleMenu = (e) => {
    e?.stopPropagation?.();
    setShowMenu(!showMenu);
  };

  const loc = window.location.pathname.split('/')[2];

  // console.log(loc);
  return (
    <>
      <div>
        {showLoginModal && (
          <LoginModal client={client} close={() => setShowLoginModal(false)} />
        )}
        {showSignupModal && (
          <SignupModal
            client={client}
            close={() => setShowSignupModal(false)}
            page="none"
          />
        )}
        <Head id="r2r-header" className="w-full flex p-3" $data={defaults}>
          <LogoContainer className="flex justify-start w-full pl-8">
            <LogoImage
              src={client === 'r2r' ? logo : defaults?.websitelogo}
              alt={defaults?.Name}
              onClick={() => {
                navigationHome();
              }}
            />
            <LogoName2>{defaults?.headerOemName}</LogoName2>
          </LogoContainer>
          {authUser?.email ? (
            <MenuContainer>
              <MenuWrapper $defaults={defaults}>
                <NavMenu
                  showAssetMenuState={showMenu}
                  showAssetMenu={(e) => toggleMenu(e)}
                />
              </MenuWrapper>
              <button className="p-3 pb-3" onClick={(e) => toggleMenu(e)}>
                <span className="fas fa-bars" />
              </button>
            </MenuContainer>
          ) : (
            <div className="flex justify-end w-full items-center pr-3 link-hover">
              <div className="flex  p-6" style={{ whiteSpace: 'nowrap' }}>
                <span>
                  <button
                    onClick={() => history.push(`/${client}/public`)}
                    style={{
                      outline: 0,
                    }}
                  >
                    <StyledStrong>{messages.publicDocs}</StyledStrong>
                  </button>
                  {/* <ALink $defaults={defaults} to={`/${client}/login`}>
                  {messages.login}
                </ALink> */}
                </span>
                <span className="text-center mr-10 ml-10">
                  <button
                    onClick={() => setShowSignupModal(true)}
                    style={{ outline: 0 }}
                  >
                    <StyledStrong>{messages.signup}</StyledStrong>
                  </button>
                  {/* <ALink $defaults={defaults} to={`/${client}/signup`}>
                    {messages.signup}
                  </ALink> */}
                </span>

                <span className="text-center mr-10">
                  <button
                    onClick={() => setShowLoginModal(true)}
                    style={{ outline: 0 }}
                  >
                    <StyledStrong>{messages.login}</StyledStrong>
                  </button>
                  {/* <ALink $defaults={defaults} to={`/${client}/login`}>
                  {messages.login}
                </ALink> */}
                </span>
              </div>
            </div>
          )}
          {authUser?.email && (
            <Menu
              showMenu={showMenu}
              setShowMenu={toggleMenu}
              messages={messages}
            />
          )}
        </Head>
      </div>
    </>
  );
}

export default Generic;
